import React from 'react'

const NotFoundPage = () => {
  return (
    <article>
      <h1 style={{ textAlign: 'center', padding: 50 }}>
        Page not found. Try to open the link using mobile phone.
      </h1>
    </article>
  )
}

export { NotFoundPage }
