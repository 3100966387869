import React from 'react'
import { withStyles } from '@material-ui/styles'
import { formatMoney } from '../../helpers'
import { fetchPaymentData } from '../../services/api.service'

const styles = theme => ({
  wrapper: {
    fontFamily: 'Poppins',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(5),
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    minWidth: '60%',
    padding: '64px',
    borderRadius: '16px',
    paddingBottom: theme.spacing(0),
    '@media (max-width: 768px)': {
      minWidth: '90%',
      padding: '16px',
    },
  },
  headerText: {
    textAlign: 'center',
    fontSize: 60,
    fontWeight: '600',
    margin: 0,
    '@media (max-width: 768px)': {
      fontSize: 24,
    },
  },
  infoText: {
    textAlign: 'center',
    fontSize: 24,
    color: '#6D676E',
    margin: 0,
    marginBottom: '64px',
    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },
  icon: {
    width: '96px',
    marginBottom: '32px',
    '@media (max-width: 768px)': {
      width: '48px',
    },
  },
  logo: {
    width: '260px',
    marginBottom: '64px',
    '@media (max-width: 768px)': {
      width: '200px',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonsSeparator: {
    width: '16px',
  },
  secondaryButton: {
    border: 'solid',
    backgroundColor: 'white',
    borderRadius: '12px',
    borderWidth: '2px',
    borderColor: '#00A7FF',
    padding: '16px',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  primaryButton: {
    border: 'solid',
    backgroundColor: '#00A7FF',
    borderRadius: '12px',
    borderWidth: '2px',
    borderColor: '#00A7FF',
    padding: '16px',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  secondaryButtonText: {
    margin: 0,
    color: '#00A7FF',
    fontWeight: '500',
    fontSize: '16px',
  },
  primaryButtonText: { // Corrected typo from "Texy" to "Text"
    margin: 0,
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
  },
  table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '70%',
    marginBottom: '16px',
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#E5E5E5',
    marginBottom: '16px',
  },
  row: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  itemTitle: {
    fontSize: '24px',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  itemCurrency: {
    fontSize: '18px',
    fontWeight: '400',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  itemAmount: {
    fontSize: '24px',
    fontWeight: '500',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  itemTotal: {
    fontSize: '24px',
    fontWeight: '600',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  footerWrapper: {
    width: '100%',
    position: 'relative',
  },
  footerDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    position: 'absolute',
    bottom: '20%',
    left: '20%',
    '@media (max-width: 900px)': {
      position: 'relative',
      bottom: 0,
      left: 0,
    },
  },
  footerImg: {
    width: '100%',
  },
  appStoreImg: {
    cursor: 'pointer',
    width: '177px',
    marginRight: theme.spacing(2)
  },
  playSoreImg: {
    cursor: 'pointer',
    width: '185px',
  },
  footerTitle: {
    fontSize: '60px',
    '@media (max-width: 1440px)': {
      fontSize: '36px',
    },
    '@media (max-width: 768px)': {
      fontSize: '24px',
    },
    fontWeight: '600',
    margin: 0,
  },
  footerText: {
    fontSize: '24px',
    '@media (max-width: 1440px)': {
      fontSize: '14px',
    },
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
    color: '#6D676E',
    margin: 0,
    marginBottom: theme.spacing(2),
  },
})

const PaymentSuccessPage = (props) => {
  const { classes } = props
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const checkoutSessionId = urlParams.get('session_id')
  const [items, setItems] = React.useState([])
  const [receiptUrl, setReceiptUrl] = React.useState('')
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [currency, setCurrency] = React.useState('USD')
  const [pending, setPending] = React.useState(false)
  const [fetchingError, setFetchingError] = React.useState('')

  const renderItem = (item, index) => {
    return (
      <div className={classes.row} key={'item-' + index}>
        <p className={index === 'total' ? classes.itemTotal : classes.itemTitle}>{item.itemData?.name}</p>
        <p className={classes.itemAmount}>
          {/* dividing amount by 100 because amount for items is in cents */}
          {formatMoney(item.itemData.amount/100, { currency: currency })}
          <span className={classes.itemCurrency}> {currency}</span>
        </p>
      </div>
    )
  }

  React.useEffect(() => {
    if (checkoutSessionId !== '') {
      setPending(true)
      fetchPaymentData({ checkoutSessionId })
        .then(peymentData => {
          setReceiptUrl(peymentData.data.stripeReceiptPdf)
          setCurrency(peymentData.data.currency.toUpperCase())
          setTotalAmount(peymentData.data.totalAmount)
          setItems(peymentData.data.items)
        })
        .catch(e => {
          setFetchingError(e.message)
          console.log(e)
        })
        .finally(() => setPending(false))
    }
  }, [checkoutSessionId])

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <div>
          <img src={'/img/logo.png'} alt={'Shipshape'} className={classes.logo} />
        </div>
        <div>
          <img src={'/img/check-icon-x96.png'} alt={'Success'} className={classes.icon} />
        </div>
        <div>
          <h1 className={classes.headerText}>Payment Successful</h1>
        </div>
        <div>
          <p className={classes.infoText}>
            Thank you for your purchase
          </p>
        </div>
        {pending
          ? <div><h1>Fetching data...</h1></div>
          : items.length > 0
            ? <div className={classes.table}>
              <div>
                {items.map(renderItem)}
              </div>
              <div className={classes.separator} />
              {/* multiplying amount by 100 to balance dividing in the renderItem method */}
              {renderItem({ itemData: { name: 'Total', amount: totalAmount * 100, currency: currency } }, 'total')}
            </div>
            : fetchingError
              ? <div><p>{fetchingError}</p></div>
              : null
        }
        <div className={classes.buttonsWrapper}>
          {!!receiptUrl &&
            <button disabled={receiptUrl === ''}
                    onClick={() => window.open(receiptUrl, '_blank')}
                    className={classes.secondaryButton}>
              <p className={classes.secondaryButtonText}>Download Receipt</p>
            </button>
          }
          <div className={classes.buttonsSeparator} />
          <button onClick={() => window.open('https://www.shipshape.ai/', '_self')} className={classes.primaryButton}>
            <p className={classes.primaryButtonText}>Go to the main page</p>
          </button>
        </div>
      </div>
      <div className={classes.footerWrapper}>
        <div className={classes.footerDescriptionWrapper}>
          <p className={classes.footerTitle}>Download the app</p>
          <p className={classes.footerText}>Your home: smart enough to take care of itself.</p>
          <div>
            <a target={'_blank'} href={'https://apps.apple.com/us/app/shipshape-home/id1504558034#?platform=iphone'}>
              <img src={'/img/download-appstore-button.png'} alt={'AppStore'} className={classes.appStoreImg} />
            </a>
            <a target={'_blank'}
               href={'https://play.google.com/store/apps/details?id=ai.shipshape.home.app&utm_source=appgrooves&utm_medium=agp_0e7901c97d38d1e7fcee968289b65b3f_ai.shipshape.home.app_us_others_16055442491799'}>
              <img src={'/img/download-playstore-button.png'} alt={'Google Play Store'}
                   className={classes.playSoreImg} />
            </a>
          </div>
        </div>
        {window.innerWidth <= 768 ? null : <img src={'/img/footer-mobile.png'} alt={'Footer'} className={classes.footerImg} />}
      </div>
    </div>
  )
}

const connectedPaymentSuccessPage = withStyles(styles)(PaymentSuccessPage)
export { connectedPaymentSuccessPage as PaymentSuccessPage }
