import { userConstants } from '../constants'

let user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { loggedIn: true, user } : {}

export function auth (state = initialState, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        requestingPassword: true,
      }
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        passwordRequested: true,
      }
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      }
    case userConstants.FORGOT_PASSWORD_FAILURE:
    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGOUT:
      return {}
    case userConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: action.notifications,
        }
      }
    case userConstants.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        pending: true,
      }
    case userConstants.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        pending: false,
      }
    case userConstants.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: action.data
        }
      }
    case userConstants.UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      }
    default:
      return state
  }
}
