import { deviceConstants } from '../constants/device.constants'

const initialState = {
  pending: false,
  data: {
    id: 'f-27949g',
    systemType: '',
    locationName: '',
    power: false,
    houseId: 'hu1004',
    lifespanCycles: 0,
    alerts: true,
    serviceLog: [],
    sensorsData: [],
    sensorsCurrentValues: [],
  },
  resolveProblemActiveStep: 0,
  error: null,
}

export function device (state = initialState, action) {
  switch (action.type) {
    case deviceConstants.POWER_ON_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          power: true,
        }
      }
    case deviceConstants.POWER_OFF_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          power: false
        }
      }
    case deviceConstants.FETCH_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          ...action.data,
          alerts: state.data.alerts,
        }
      }
    case deviceConstants.FETCH_POWER_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          power: action.data
        }
      }
    case deviceConstants.SNOOZE_ALERT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          alerts: action.data.alerts
        }
      }
    case deviceConstants.SET_ACTIVE_STEP_SUCCESS:
      return {
        ...state,
        pending: false,
        resolveProblemActiveStep: action.data.resolveProblemActiveStep
      }
    case deviceConstants.GET_DEVICE_SERVICE_LOG_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          serviceLog: action.data,
        }
      }
    case deviceConstants.GET_SENSORS_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          sensorsData: action.data,
        }
      }
    case deviceConstants.GET_SENSORS_CURRENT_VALUES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          sensorsCurrentValues: action.data,
        }
      }
    case deviceConstants.GET_SENSORS_LOGS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          sensorLogs: action.data,
        }
      }
    case deviceConstants.SET_LAST_STEP_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          alerts: false,
        },
        resolveProblemActiveStep: action.data
      }
    case deviceConstants.SET_LAST_STEP_FAILURE:
      return {
        ...state,
        pending: false,
        resolveProblemActiveStep: action.error
      }
    case deviceConstants.POWER_ON_FAILURE:
    case deviceConstants.POWER_OFF_FAILURE:
    case deviceConstants.FETCH_DATA_FAILURE:
    case deviceConstants.FETCH_POWER_STATUS_FAILURE:
    case deviceConstants.GET_DEVICE_SERVICE_LOG_FAILURE:
    case deviceConstants.GET_SENSORS_DATA_FAILURE:
    case deviceConstants.GET_SENSORS_CURRENT_VALUES_FAILURE:
    case deviceConstants.GET_SENSORS_LOGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.data
      }
    case deviceConstants.POWER_ON_REQUEST:
    case deviceConstants.POWER_OFF_REQUEST:
    case deviceConstants.FETCH_DATA_REQUEST:
    case deviceConstants.FETCH_POWER_STATUS_REQUEST:
    case deviceConstants.SNOOZE_ALERT_REQUEST:
    case deviceConstants.SET_ACTIVE_STEP_REQUEST:
    case deviceConstants.SET_LAST_STEP_REQUEST:
    case deviceConstants.GET_DEVICE_SERVICE_LOG_REQUEST:
    case deviceConstants.GET_SENSORS_DATA_REQUEST:
    case deviceConstants.GET_SENSORS_CURRENT_VALUES_REQUEST:
    case deviceConstants.GET_SENSORS_LOGS_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      }
    default:
      return state
  }
}

export const getDeviceData = state => state.device.data
