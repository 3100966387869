import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { withStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import LockIcon from '@material-ui/icons/Lock';

import { userActions } from '../../actions'
import TextField from '../../components/base/TextField'
import Button from '../../components/base/Button'
import { notifierActions } from '../../actions/notifier.actions'
import { AuthPageWrapper } from '../AuthPageWrapper'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

class ChangePasswordPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: '',
      submitted: false,
      showPassword: false,
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { password, confirmPassword } = this.state
    const { enqueueSnackbar, location } = this.props
    if (password.length < 8) {
      return enqueueSnackbar({
        message: 'Passwords should have 8 or more symbols',
        options: {
          variant: 'warning',
        },
      })
    }
    if (password !== confirmPassword) {
      return enqueueSnackbar({
        message: 'Passwords not match',
        options: {
          variant: 'warning',
        },
      })
    }

    if (password) {
      const params = queryString.parse(location.search)
      this.setState({ submitted: true }, async () => {
        await this.props.changePassword(params.forgotKey, password)
        this.setState({ submitted: false })
      })
    } else {
      enqueueSnackbar({
        message: 'Fill password',
        options: {
          variant: 'warning',
        },
      })
    }
  }

  render () {
    const { classes } = this.props
    const { showPassword, submitted } = this.state

    return (
      <AuthPageWrapper
        title={'Welcome back'}
        subtitle={'Set new password'}
      >
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            type={showPassword ? 'text' : 'password'}
            onChange={this.handleChange}
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <LockIcon />
              //   </InputAdornment>
              // ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ showPassword: !showPassword })}
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            id="confirm-password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="confirm-password"
            onChange={this.handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            loading={submitted}
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </AuthPageWrapper>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.auth
  return { loggingIn }
}

const actionCreators = {
  changePassword: userActions.changePassword,
  enqueueSnackbar: notifierActions.enqueueSnackbar,
}

const connectedChangePasswordPage = connect(mapState, actionCreators)(withStyles(styles)(ChangePasswordPage))
export { connectedChangePasswordPage as ChangePasswordPage }
