import React, { useState } from 'react'
import { Box, withStyles } from '@material-ui/core'
import Button from '../../components/base/Button'
import api from '../../services/api.service'
import { history } from '../../helpers'
import { useSnackbar } from 'notistack'

const styles = theme => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: '200px',
    margin: theme.spacing(2, 3, 2)
  },
  textGrant: {
    display: 'inline',
    color: theme.palette.secondary.main,
  },
  textDecline: {
    display: 'inline',
    color: theme.palette.error.main,
  },
})

const AccessRequestPage = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const dealerAccessRequestId = urlParams.get('dealerAccessRequestId')
  const accessState = urlParams.get('accessState')
  const changeStateKey = urlParams.get('changeStateKey')
  const dealerName = urlParams.get('dealerName')
  const accessType = urlParams.get('accessType')
  const homeName = urlParams.get('houseName')
  if (!dealerAccessRequestId || !accessState || !changeStateKey || !dealerName || !accessType) {
    history.push('')
  }
  const { enqueueSnackbar } = useSnackbar()
  const [pending, setPending] = useState(false)
  const { classes } = props

  const handleSubmit = async () => {
    setPending(true)
    try {
      const requestOptions = {
        method: 'PUT',
        data: {},
      }
      const requestUrl = encodeURI(`dealeraccessrequests/request/${dealerAccessRequestId}/state/${accessState}/changeStateKey/${changeStateKey}`)
      api(requestUrl, requestOptions).then(({ data }) => {
        console.log(data)
        enqueueSnackbar('Request state changed successfully', {
          variant: 'success',
        })
        history.push('/mobile')
      }).catch(e => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        })
        console.log(e)
      })
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      })
      console.log(e)
    }
    setPending(false)
  }

  return (
    <article>
      <h1 style={{ textAlign: 'center' }}>
        {`You are about to `}
        <h4 className={accessState === 'GRANTED' ? classes.textGrant : classes.textDecline}>
          {accessState === 'GRANTED' ? 'GRANT' : 'DECLINE'}
        </h4>{` access to your ${accessType === 'USER' ? 'account' : ('house "' + homeName + '"')} for dealer "${dealerName}"`}
      </h1>
      <Box className={classes.buttonsWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          loading={pending}
          className={classes.button}
          onClick={() => handleSubmit()}
        >
          {accessState === 'GRANTED' ? 'GRANT' : 'DECLINE'}
        </Button>
      </Box>
    </article>
  )
}

const AccessRequestPageWithStyles = withStyles(styles)(AccessRequestPage)
export { AccessRequestPageWithStyles as AccessRequestPage }
