export const houseConstants = {
  FETCH_HOUSE_DATA_REQUEST: 'FETCH_HOUSE_DATA_REQUEST',
  FETCH_HOUSE_DATA_SUCCESS: 'FETCH_HOUSE_DATA_SUCCESS',
  FETCH_HOUSE_DATA_FAILURE: 'FETCH_HOUSE_DATA_FAILURE',

  UPDATE_HOUSE_DATA_REQUEST: 'UPDATE_HOUSE_DATA_REQUEST',
  UPDATE_HOUSE_DATA_SUCCESS: 'UPDATE_HOUSE_DATA_SUCCESS',
  UPDATE_HOUSE_DATA_FAILURE: 'UPDATE_HOUSE_DATA_FAILURE',

  HOUSE_SENSORS_SNAPSHOT_REQUEST: 'HOUSE_SENSORS_SNAPSHOT_REQUEST',
  HOUSE_SENSORS_SNAPSHOT_SUCCESS: 'HOUSE_SENSORS_SNAPSHOT_SUCCESS',
  HOUSE_SENSORS_SNAPSHOT_FAILURE: 'HOUSE_SENSORS_SNAPSHOT_FAILURE',
}
