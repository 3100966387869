import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Grid from '@material-ui/core/Grid'

import { userActions } from '../../actions'
import TextField from '../../components/base/TextField'
import Button from '../../components/base/Button'
import { notifierActions } from '../../actions/notifier.actions'
import { AuthPageWrapper } from '../AuthPageWrapper'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

class SignUpPage extends React.Component {
  constructor (props) {
    super(props)
    const urlParams = new URLSearchParams(window.location.search)
    const refDealer = urlParams.get('refDealer')
    const dealerName = urlParams.get('dealerName')
    const email = sessionStorage.getItem('email') || '';

    this.state = {
      email,
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      phone: '',
      submitted: false,
      showPassword: false,
      refDealerId: refDealer,
      dealerName: dealerName,
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { email, password, firstName, lastName, phone, confirmPassword, refDealerId } = this.state
    const { enqueueSnackbar } = this.props
    if (password !== confirmPassword) {
      return enqueueSnackbar({
        message: 'Passwords not match',
        options: {
          variant: 'warning',
        },
      })
    }

    if (email && password && firstName && lastName) {
      this.setState({ submitted: true }, async () => {
        await this.props.register({
          email,
          password,
          firstname: firstName,
          lastname: lastName,
          phone,
          refDealerId: refDealerId
        })
        this.setState({ submitted: false })
      })
    } else {
      enqueueSnackbar({
        message: 'Fill all fields',
        options: {
          variant: 'warning',
        },
      })
    }
  }

  render () {
    const { classes } = this.props
    const { showPassword, submitted, dealerName, refDealerId } = this.state

    return (
      <AuthPageWrapper
        title={'Create account'}
      >
        {refDealerId && dealerName && <Typography>
          {`By registering you also agree to grant access to your account for dealer "${dealerName}"`}
        </Typography>}
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mr={2}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={this.handleChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6} ml={2}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                autoComplete="phone"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                onChange={this.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => this.setState({ showPassword: !showPassword })}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                id="confirm-password"
                type={showPassword ? 'text' : 'password'}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            loading={submitted}
            className={classes.submit}
          >
            {'Sign In'}
          </Button>
        </form>
      </AuthPageWrapper>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.auth
  return { loggingIn }
}

const actionCreators = {
  register: userActions.register,
  enqueueSnackbar: notifierActions.enqueueSnackbar,
}

const connectedSignUpPage = connect(mapState, actionCreators)(withStyles(styles)(SignUpPage))
export { connectedSignUpPage as SignUpPage }
