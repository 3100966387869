import React from 'react'

const OpenFromMobile = () => {
  return (
    <article>
      <h1 style={{ textAlign: 'center' }}>
        Operation successful. Now you can close this page and use ShipShape Homeowner mobile application.
      </h1>
    </article>
  )
}

export { OpenFromMobile }
