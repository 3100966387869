export function units (type, settings = []) {
  switch (type) {
    case 'TEMPERATURE':
      let format = '\u00b0F'
      settings.forEach(setting => {
        if ('temperature' === setting.optionName) {
          format = '\u00b0' + setting.optionValue
        }
      })
      return format
    case 'HUMIDITY':
      return '%'
    default:
      return ''
  }
}

export function formatMoney (amount, options = {}) {
  const defaultOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  return amount.toLocaleString('en-US', { ...defaultOptions, ...options }).replace(/(\D)(\d)/, "$1 $2")
}
