import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Copyright } from './Copyright'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  logo: {
    color: '#738695',
    fontSize: 28,
    letterSpacing: 0,
  }
}))

export const Footer = () => {
  const classes = useStyles()
  const hideFooter = window.location.href.includes('/payment-success')

  return hideFooter ? null
    : <Box p={3} display="flex" flexDirection="column" alignItems="center" mt="auto">
      <Box fontFamily="Raleway" fontWeight={900} className={classes.logo}>SHIPSHAPE</Box>
      <Copyright />
    </Box>

}
