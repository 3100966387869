import { notifierConstants } from '../constants/notifier.constants'

export const notifierActions = {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
}

function enqueueSnackbar (notification) {
  return dispatch => {
    const key = notification.options && notification.options.key

    dispatch({
      type: notifierConstants.ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    })
  }
}

function closeSnackbar (key) {
  return dispatch => {
    dispatch({
      type: notifierConstants.CLOSE_SNACKBAR,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    })
  }
}

function removeSnackbar (key) {
  return dispatch => {
    dispatch({
      type: notifierConstants.REMOVE_SNACKBAR,
      key,
    })
  }
}
