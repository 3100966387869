import { API_URL } from '../constants/base.constants'
import { translationService } from './translation.service'
import Push from 'push.js'
import { store } from './../helpers/store'
import { userConstants } from '../constants'
import { userService } from './user.service'

let eventSource = null
const notifications = []

export const notificationService = {
  init,
  getNotifications,
  closeNotification,
  destroy
}

function getNotifications () {
  return notifications
}

function addNotification (data) {

  const response = translationService.translate(data)

  notifications.unshift(response.notification)
  store.dispatch({ type: userConstants.UPDATE_NOTIFICATIONS, data: notifications })

  if (userService.hasPushPermissions() && isPushNotificationSupported()) {

    const state = translationService.translateState(response.notification.systemType, 'state', response.notification.state)
    const risk = translationService.translateState(response.notification.systemType, 'risk', response.notification.risk)
    const actionRequired = translationService.translateState(response.notification.systemType, 'actionRequired', response.notification.actionRequired)
    Push.create(
      'ShipShape alert',
      {
        body: state + ' ' + risk + ' ' + actionRequired,
        icon: {
          x16: '/img/notifications/' + response.notification.priority.toLowerCase() + '-x16.png',
          x32: '/img/notifications/' + response.notification.priority.toLowerCase() + '-x32.png'
        },
        timeout: 5000,
        onClick: function () {
          window.focus()
          this.cancel()
        }
      })
  }
}

function init () {

  if (!eventSource || eventSource.readyState === 2) {
    try {
      eventSource = new EventSource(API_URL + '/broadcast', {
        withCredentials: true
      })
    } catch (e) {
      return 0
    }

    eventSource.onmessage = e => {
      const data = JSON.parse(e.data)

      const alert = {
        priority: data.priority,
        state: data.state,
        risk: data.risk,
        actionRequired: data.actionRequired,
        houseId: data.houseId,
        applianceId: data.applianceId,
        systemType: data.systemType,
        timestamp: data.timestamp
      }
      addNotification(alert)
    }
  }
}

function closeNotification (index) {
  notifications.splice(index, 1)
  return notifications
}

function destroy () {
  if (eventSource !== null && eventSource.readyState !== 2) {
    eventSource.close()
    eventSource = null
  }
}

function isPushNotificationSupported () {
  return 'serviceWorker' in navigator && 'PushManager' in window
}
