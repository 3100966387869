export const deviceConstants = {
  LIFESPAN_CYCLES_AVG: 5000,

  POWER_ON_ACTION: 'TURN_ON',
  POWER_ON_REQUEST: 'POWER_ON_REQUEST',
  POWER_ON_SUCCESS: 'POWER_ON',
  POWER_ON_FAILURE: 'POWER_ON_FAILURE',
  POWER_OFF_ACTION: 'TURN_OFF',
  POWER_OFF_REQUEST: 'POWER_OFF_REQUEST',
  POWER_OFF_SUCCESS: 'POWER_OFF',
  POWER_OFF_FAILURE: 'POWER_OFF_FAILURE',

  FETCH_POWER_STATUS_REQUEST: 'FETCH_POWER_STATUS_REQUEST',
  FETCH_POWER_STATUS_SUCCESS: 'FETCH_POWER_STATUS_SUCCESS',
  FETCH_POWER_STATUS_FAILURE: 'FETCH_POWER_STATUS_FAILURE',

  FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',

  SNOOZE_ALERT_REQUEST: 'SNOOZE_ALERT_REQUEST',
  SNOOZE_ALERT_SUCCESS: 'SNOOZE_ALERT_SUCCESS',

  SET_ACTIVE_STEP_REQUEST: 'SET_ACTIVE_STEP_REQUEST',
  SET_ACTIVE_STEP_SUCCESS: 'SET_ACTIVE_STEP_SUCCESS',
  SET_ACTIVE_STEP_FAILURE: 'SET_ACTIVE_STEP_FAILURE',

  SET_LAST_STEP_REQUEST: 'SET_LAST_STEP_REQUEST',
  SET_LAST_STEP_SUCCESS: 'SET_LAST_STEP_SUCCESS',
  SET_LAST_STEP_FAILURE: 'SET_LAST_STEP_FAILURE',

  GET_DEVICE_SERVICE_LOG_REQUEST: 'GET_DEVICE_SERVICE_LOG_REQUEST',
  GET_DEVICE_SERVICE_LOG_SUCCESS: 'GET_DEVICE_SERVICE_LOG_SUCCESS',
  GET_DEVICE_SERVICE_LOG_FAILURE: 'GET_DEVICE_SERVICE_LOG_FAILURE',

  GET_SENSORS_DATA_REQUEST: 'GET_SENSORS_DATA_REQUEST',
  GET_SENSORS_DATA_SUCCESS: 'GET_SENSORS_DATA_SUCCESS',
  GET_SENSORS_DATA_FAILURE: 'GET_SENSORS_DATA_FAILURE',

  GET_SENSORS_CURRENT_VALUES_REQUEST: 'GET_SENSORS_CURRENT_VALUES_REQUEST',
  GET_SENSORS_CURRENT_VALUES_SUCCESS: 'GET_SENSORS_CURRENT_VALUES_SUCCESS',
  GET_SENSORS_CURRENT_VALUES_FAILURE: 'GET_SENSORS_CURRENT_VALUES_FAILURE',

  GET_SENSORS_LOGS_REQUEST: 'GET_SENSORS_LOGS_REQUEST',
  GET_SENSORS_LOGS_SUCCESS: 'GET_SENSORS_LOGS_SUCCESS',
  GET_SENSORS_LOGS_FAILURE: 'GET_SENSORS_LOGS_FAILURE',
}
