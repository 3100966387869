import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField/index'

const useStyles = makeStyles({
  root: {},
})

export default props => {
  const classes = useStyles(props)
  return <TextField classes={{ root: classes.root }} {...props} />
};
