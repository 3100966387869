import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
// import EmailIcon from '@material-ui/icons/Email';
// import LockIcon from '@material-ui/icons/Lock';

import { userActions } from '../../actions'
import TextField from '../../components/base/TextField'
import Button from '../../components/base/Button'
import { notifierActions } from '../../actions/notifier.actions'
import { AuthPageWrapper } from '../AuthPageWrapper'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

class ForgotPasswordPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      submitted: false,
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { email } = this.state
    const { enqueueSnackbar } = this.props
    if (email) {
      this.setState({ submitted: true }, async () => {
        await this.props.forgotPassword(email)
        this.setState({ submitted: false })
      })
    } else {
      enqueueSnackbar({
        message: 'Fill email',
        options: {
          variant: 'warning',
        },
      })
    }
  }

  render () {
    const { classes, passwordRequested } = this.props
    const { submitted } = this.state

    return (
      <AuthPageWrapper
        title={passwordRequested ? 'Instructions have been sent' : 'Forgot your password?'}
        subtitle={passwordRequested ? '' : 'Enter your email address below and we will send you instructions on how to change your password'}
      >
        {!passwordRequested &&
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <EmailIcon />
              //   </InputAdornment>
              // ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            loading={submitted}
            className={classes.submit}
          >
            Send me instructions
          </Button>
        </form>}
      </AuthPageWrapper>
    )
  }
}

function mapState (state) {
  const { requestingPassword, passwordRequested } = state.auth
  return { requestingPassword, passwordRequested }
}

const actionCreators = {
  forgotPassword: userActions.forgotPassword,
  enqueueSnackbar: notifierActions.enqueueSnackbar,
}

const connectedForgotPasswordPage = connect(mapState, actionCreators)(withStyles(styles)(ForgotPasswordPage))
export { connectedForgotPasswordPage as ForgotPasswordPage }
