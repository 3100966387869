import { prettify } from '../helpers'

export const translationService = {
  translate,
  translateState,
}

const statusMap = {
  'SUMP_PUMP': {
    'state': {
      'STAND_BY': '{appliance} Standing By',
      'WORKING': '{appliance} is Working',
      'WORKING_WATER_RISING': '{appliance} Working, Water Rising',
      'PUMP_RUNNING_DRY': '{appliance} Running Dry',
      'NO_POWER_TO_OUTLET': 'No Power to {appliance}',
      'NO_POWER_TO_CONTROLLER': 'No Power to Controller',
      'NO_POWER_TO_HOME': 'No Power to Controller',
      'PUMP_NOT_WORKING': '{appliance} Failure',
      'WATER_SENSOR_ALARM_PUMP_NOT_WORKING': 'Water sensor Alarm! {appliance} is not working',
      'WATER_SENSOR_ALARM_WORKING_TOO_SLOW': 'Water sensor Alarm! {appliance} is working too slowly',
      'WATER_SENSOR_ALARM_PUMP_IS_TOO_SMALL_CANNOT_DRAIN_ALL_WATER': 'Water sensor Alarm! {appliance} is too small and cannot drain all water',
      'WATER_SENSOR_ALARM_WORKING_WITH_HIGH_TENSION': 'Water sensor Alarm! {appliance} is working to hard',
      'PUMPING_WATER': '{appliance} is pumping water',
      'PUMPING_WATER_WITH_HIGH_TENSION': '{appliance} is pumping water, but working too hard',
      'SENSOR_IS_OFFLINE': '{appliance} sensor is offline'
    },
    'risk': {
      'NONE': '{house} is Secure',
      'MOTOR_BURN_OUT': 'Motor at Risk of Failure at {house}',
      'FLOOD': 'Flood Threat Present at {house}',
      'FLOOD_WATER_RISING': 'Flood Threat Present, Water Rising at {house}'
    },
    'actionRequired': {
      'NONE': 'No Action Required',
      'TURN_OFF': 'Turn Off {appliance}',
      'URGENT_SERVICE': 'Urgent Service Required',
      'RESTORE_POWER_TO_OUTLET': 'Restore Power to {appliance}',
      'RESTORE_POWER_TO_HOME': 'Restore Power to Controller',
      'REPLACE_PUMP': '{appliance} Replacement Required',
      'RESTORE_POWER_TO_CONTROLLER': 'Restore Power to the Controller',
      'MAKE_SENSOR_ONLINE': 'Connection to sensor should be restored'
    }
  },
  'DEHUMIDIFIER': {
    'state': {
      'STAND_BY': '{appliance} Standing By',
      'WORKING': '{appliance} is Working',
      'WORKING_TOO_LONG': '{appliance} Running Constantly',
      'BROKEN': '{appliance} Failure',
      'NO_POWER_TO_OUTLET': 'No Power to {appliance}',
      'NO_POWER_TO_CONTROLLER': 'No Power to Controller',
      'NO_POWER_TO_HOME': 'No Power to Controller',
      'SENSOR_IS_OFFLINE': '{appliance} sensor is offline',
      'WORKING_LOW_POWER_HIGH_HUMIDITY': '{appliance} is working with low power consumption, but humidity is high',
      'WORKING_BUT_DRY_AIR': '{appliance} is working, but humidity level is low',
      'WORKING_BUT_HIGH_HUMIDITY': '{appliance}  is working, but humidity level is high',
      'ABNORMAL_POWER': '{appliance} is working with high power consumption',
    },
    'risk': {
      'NONE': '{house} is Secure',
      'MOLD': 'Humidity risk at {house}'
    },
    'actionRequired': {
      'NONE': 'No Action Required',
      'TURN_OFF': 'Turn off the {appliance}',
      'URGENT_SERVICE': 'Urgent Service Required',
      'RESTORE_POWER_TO_OUTLET': 'Restore Power to the {appliance}',
      'RESTORE_POWER_TO_HOME': 'Restore Power to the Controller',
      'REPLACE_DEHUMIDIFIER': '{appliance} Replacement Required',
      'RESTORE_POWER_TO_CONTROLLER': 'Restore Power to the Controller',
      'MAKE_SENSOR_ONLINE': 'Connection to sensor should be restored'
    }
  },
  'HOT_WATER_HEATER': {
    'state': {
      'STAND_BY': '{appliance} Standing By',
      'WORKING': '{appliance} is Working',
      'WORKING_UNNORMAL_POWER': '{appliance} Power Consumption Abnormal',
      'WATER_LEAK': '{appliance} Leaking',
      'NO_POWER_TO_CONTROLLER': 'No Power to Controller',
      'NO_POWER_TO_HOME': 'No Power to Controller',
      'SENSOR_IS_OFFLINE': '{appliance} sensor is offline'
    },
    'risk': {
      'NONE': '{house} is Secure',
      'FLOOD': 'Flood risk at {house}',
      'HEATING_SPIRAL_BURNOUT': 'Heating spiral burnout risk at {house}'
    },
    'actionRequired': {
      'NONE': 'No Action Required',
      'URGENT_SERVICE': 'Urgent Service Required',
      'RESTORE_POWER_TO_HOME': 'Restore Power to the Controller',
      'RESTORE_POWER_TO_CONTROLLER': 'Restore Power to the Controller',
      'MAKE_SENSOR_ONLINE': 'Connection to sensor should be restored'
    }
  },
  'THERMO_HYGROMETER': {
    'state': {
      'NORMAL': '{appliance} Environment is Comfortable',
      'NO_POWER_TO_CONTROLLER': 'No Power to Controller',
      'DRY': '{appliance} Low Humidity Level',
      'WET': '{appliance} High Humidity Level',
      'HUMIDITY_TOO_HIGH': '{appliance} High Humidity Level',
      'SENSOR_IS_OFFLINE': '{appliance} sensor is offline'
    },
    'risk': {
      'NONE': 'No risk in {house}',
      'CANNOT_ANALYZE_NEW_DATA': 'Can\'t analyse new data for {house}',
      'MOLD': 'Humidity risk in {house}'
    },
    'actionRequired': {
      'NONE': 'No action required',
      'DECREASE_HUMIDITY': 'Reduce humidity',
      'RESTORE_POWER_TO_CONTROLLER': 'Restore Power to the Controller',
      'MAKE_SENSOR_ONLINE': 'Connection to sensor should be restored'
    }
  },
  'WATER_LEAK_DETECTOR': {
    'state': {
      'STAND_BY': '{appliance} Standing By',
      'NO_POWER_TO_CONTROLLER': 'No Power to Controller',
      'WATER_LEAK': '{appliance} Leaking',
      'SENSOR_IS_OFFLINE': '{appliance} sensor is offline'
    },
    'risk': {
      'NONE': 'No risk in {house}',
      'FLOOD': 'Flood Threat Present at {house}'
    },
    'actionRequired': {
      'NONE': 'No action required',
      'ELIMINATE_LEAKAGE': 'Eliminate Leakage',
      'RESTORE_POWER_TO_CONTROLLER': 'Restore Power to the Controller',
      'MAKE_SENSOR_ONLINE': 'Connection to sensor should be restored'
    }
  }
}

export function translate (broadcastData) {
  return combineNotificationRecordsData({ notification: broadcastData })
}

function combineNotificationRecordsData (recordsData) {
  const records = recordsData

  for (let [key, record] of Object.entries(recordsData)) {
    //TODO: remove hardcoded urls
    const applianceLinkHtml = '<a href="/homes/' + record.houseId + '/appliances/' + record.applianceId + '/summary" class="font-weight-sebold">' + prettify(record.systemType) + '</a>'
    // waiting for houseTitle property in getHistory endpoint

    //var houseLinkHtml = '<a ui-sref="app.house.summary({houseId:' + record.houseId + '})">' + record.houseTitle + '</a>';

    const houseLinkHtml = '<a href="/homes/' + record.houseId + '/summary" class="font-weight-sebold">Your Home</a>'

    records[key].message = {}
    if (record.systemType in statusMap) {
      records[key].message.state = statusMap[record.systemType].state[record.state] + '.'
      records[key].message.risk = statusMap[record.systemType].risk[record.risk] + '.'
      records[key].message.actionRequired = statusMap[record.systemType].actionRequired[record.actionRequired] + '.'
    } else {
      records[key].message.state = prettify(record.state) + '. '
      records[key].message.risk = prettify(record.risk + '. ')
      records[key].message.actionRequired = prettify(record.actionRequired + '.')
    }

    records[key].message.state = records[key].message.state.replace('{appliance}', applianceLinkHtml)
    records[key].message.state = records[key].message.state.replace('{house}', houseLinkHtml)
    records[key].message.risk = records[key].message.risk.replace('{appliance}', applianceLinkHtml)
    records[key].message.risk = records[key].message.risk.replace('{house}', houseLinkHtml)
    records[key].message.actionRequired = records[key].message.actionRequired.replace('{appliance}', applianceLinkHtml)
    records[key].message.actionRequired = records[key].message.actionRequired.replace('{house}', houseLinkHtml)
  }

  return records
}

function translateState (systemType, stateType, state) {
  if (statusMap[systemType] !== undefined &&
    statusMap[systemType][stateType] !== undefined &&
    statusMap[systemType][stateType][state] !== undefined) {
    const data = statusMap[systemType][stateType][state]
    return data.replace('{appliance}', 'Appliance').replace('{house}', 'the House')
  }
  return prettify(state)
}
