import { houseConstants } from '../constants/house.constants'

const initialState = {
  pending: false,
  data: {
    houseTitle: 'Home',
    street: 'Street',
    city: 'City',
    state: 'State',
    installedSystems: [],
    recommendInstall: [],
    sensorsSnapshot: true,
  },
  error: null,
}

export function house (state = initialState, action) {
  switch (action.type) {
    case houseConstants.FETCH_HOUSE_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...action.data,
          power: state.data.power
        }
      }
    case houseConstants.HOUSE_SENSORS_SNAPSHOT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          ...action.data,
          power: state.data.power
        }
      }
    case houseConstants.UPDATE_HOUSE_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          power: state.data.power
        },
        pending: false,
      }
    case houseConstants.FETCH_HOUSE_DATA_FAILURE:
    case houseConstants.UPDATE_HOUSE_DATA_FAILURE:
    case houseConstants.HOUSE_SENSORS_SNAPSHOT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.data
      }
    case houseConstants.FETCH_HOUSE_DATA_REQUEST:
    case houseConstants.UPDATE_HOUSE_DATA_REQUEST:
    case houseConstants.HOUSE_SENSORS_SNAPSHOT_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      }
    default:
      return state
  }
}

export const getHouseData = state => state.house.data
