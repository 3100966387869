import React from 'react'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  wrapper: {
    fontFamily: 'Poppins',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(1),
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    maxWidth: '60%', // Default for desktop
    padding: '64px', // Default for desktop
    borderRadius: '16px',
    paddingBottom: theme.spacing(0),
    '@media (max-width: 768px)': {
      maxWidth: '90%',
      padding: '16px',
    },
  },
  headerText: {
    textAlign: 'center',
    fontSize: 60, // Default for desktop
    fontWeight: '600',
    margin: 0,
    '@media (max-width: 768px)': {
      fontSize: 24,
    },
  },
  infoText: {
    textAlign: 'center',
    fontSize: 24, // Default for desktop
    color: '#6D676E',
    margin: 0,
    marginBottom: '64px',
    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },
  icon: {
    width: '96px', // Default for desktop
    marginBottom: '32px',
    '@media (max-width: 768px)': {
      width: '48px',
    },
  },
  logo: {
    width: '260px', // Default for desktop
    marginBottom: '64px',
    '@media (max-width: 768px)': {
      width: '200px',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonsSeparator: {
    width: '16px',
  },
  secondaryButton: {
    border: 'solid',
    backgroundColor: 'white',
    borderRadius: '12px',
    borderWidth: '2px',
    borderColor: '#00A7FF',
    padding: '16px',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  primaryButton: {
    border: 'solid',
    backgroundColor: '#00A7FF',
    borderRadius: '12px',
    borderWidth: '2px',
    borderColor: '#00A7FF',
    padding: '16px',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  secondaryButtonText: {
    margin: 0,
    color: '#00A7FF',
    fontWeight: '500',
    fontSize: '16px',
  },
  primaryButtonText: {
    margin: 0,
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
  },
})

class PaymentFailedPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <div>
            <img src={'/img/logo.png'} alt={'Shipshape'} className={classes.logo} />
          </div>
          <div>
            <img src={'/img/cross-icon-x96.png'} alt={'Faied'} className={classes.icon} />
          </div>
          <div>
            <h1 className={classes.headerText}>Payment Failed</h1>
          </div>
          <div>
            <p className={classes.infoText}>
              Please try again later or use another payment method
            </p>
          </div>
          <div className={classes.buttonsWrapper}>
            <button onClick={() => window.open('https://www.shipshape.ai/contact-us', '_self')}
                    className={classes.secondaryButton}>
              <p className={classes.secondaryButtonText}>Contact Us</p>
            </button>
            <div className={classes.buttonsSeparator} />
            <button onClick={() => window.open('https://www.shipshape.ai/', '_self')} className={classes.primaryButton}>
              <p className={classes.primaryButtonText}>Go to the main page</p>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const connectedPaymentFailedPage = withStyles(styles)(PaymentFailedPage)
export { connectedPaymentFailedPage as PaymentFailedPage }
