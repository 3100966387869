import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: '#333333',
    fontSize: 22,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: 16,
    color: '#666666',
    marginBottom: theme.spacing(2),
  },
  logo: {
    color: '#235789',
    fontSize: 37,
    marginBottom: theme.spacing(10),
    letterSpacing: 0,
  },
}))

const AuthPageWrapper = props => {
  const classes = useStyles()

  const { children, title, subtitle } = props

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Box fontFamily="Raleway" fontWeight={900} className={classes.logo}>
          SHIPSHAPE
        </Box>
        <Box className={classes.title}>
          {title}
        </Box>
        <Box className={classes.subtitle}>
          {subtitle}
        </Box>
        {children}
      </div>
    </Container>
  )
}

export { AuthPageWrapper }
