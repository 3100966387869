import { systemConstants } from '../constants/system.constants'
import { iconConstants } from '../constants/icon.constants'

const initialState = {
  pending: false,
  data: [],
  error: null,
  fixOptions: [],
}

export function system (state = initialState, action) {
  switch (action.type) {
    case systemConstants.FETCH_SYSTEM_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          ...action.data,
        },
      }
    case systemConstants.FETCH_SYSTEM_FIX_OPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
        },
        fixOptions: action.data,
      }
    case systemConstants.FETCH_SYSTEM_DATA_FAILURE:
    case systemConstants.FETCH_SYSTEM_FIX_OPTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.data
      }
    case systemConstants.FETCH_SYSTEM_DATA_REQUEST:
    case systemConstants.FETCH_SYSTEM_FIX_OPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      }
    default:
      return state
  }
}

export const getSystemsData = state => state.system.data

export const getCategorySystemsData = (state, category) => {
  let systems = []
  for (let value of Object.values(state.system.data)) {
    if (category === value.appliance.systemCategory) {
      systems.push(value)
    }
  }
  return systems
}

export const getSystemCategories = state => {
  const systemCategories = []
  for (let value of Object.values(state.system.data)) {
    if (-1 === systemCategories.indexOf(value.appliance.systemCategory)) {
      systemCategories.push(value.appliance.systemCategory)
    }
  }
  //TODO remove this mock
  systemCategories.push(
    iconConstants.AC_SYSTEM,
    iconConstants.OTHER_SYSTEMS,
  )
  return systemCategories
}

export const getLastApplianceEvent = (state, applianceId) => {
  return state.system.data[applianceId] ? state.system.data[applianceId].lastApplianceEvent : []
}
