export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_FAILURE: 'UPDATE_NOTIFICATIONS_FAILURE',
}
