function replaceUnderscoresWithSpaces (data) {
  if ((data !== undefined) && (data !== null)) {
    return data.replace(/_/g, ' ')
  } else {
    return null
  }
}

function toTitleCase (data) {
  if ((data !== undefined) && (data !== null)) {
    return data.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  } else {
    return null
  }
}

export function prettify (textData) {
  return toTitleCase(replaceUnderscoresWithSpaces(textData))
}
