import axios from 'axios'
import { API_URL } from '../constants'

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

function errorResponseHandler (error) {

  if (error.response && 401 === error.response.status) {
    localStorage.removeItem('user')
    return window.location = '/login'
  }

  // check for errorHandle config
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === true) {
    // if has response show the error
    console.log(error.response)
  }

  return Promise.reject(error)
}

api.defaults.headers.common['Content-Type'] = 'application/json'

api.interceptors.response.use(
  response => response,
  errorResponseHandler
)

export default api

export const fetchPaymentData = async (params) => {
  try {
    const { checkoutSessionId } = params
    const response = await axios.get(API_URL +`/stripe/checkoutsession/${encodeURIComponent(checkoutSessionId)}/data`, {})

    return response.data
  } catch (error) {
    console.error('Error fetching payment data:', error)
    throw error
  }
}
