import React, { useState } from 'react'
import { Box, withStyles } from '@material-ui/core'
import Button from '../../components/base/Button'
import api from '../../services/api.service'
import { history } from '../../helpers'
import { useSnackbar } from 'notistack'

const styles = theme => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: '200px',
    margin: theme.spacing(2, 3, 2)
  },
  textGrant: {
    display: 'inline',
    color: theme.palette.secondary.main,
  },
  textDecline: {
    display: 'inline',
    color: theme.palette.error.main,
  },
})

const HouseMigrationPage = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const state = urlParams.get('state')
  const migrationSecretKey = urlParams.get('migrationSecretKey')
  const houseMigrationRequestId = urlParams.get('houseMigrationRequestId')
  const houseName = urlParams.get('houseName') || ''
  const email = urlParams.get('email') || ''

  if (!state || !migrationSecretKey || !houseMigrationRequestId) {
    history.push('')
  }
  const { enqueueSnackbar } = useSnackbar()
  const [pending, setPending] = useState(false)
  const { classes } = props

  React.useEffect(() => {
    if (sessionStorage.getItem('autoDecide')) {
      sessionStorage.removeItem('autoDecide')
      handleSubmit();
    }
  }, [])

  const handleSubmit = async () => {
    setPending(true)
    try {
      const requestOptions = {
        method: 'PUT',
        data: {
          migrationSecretKey,
          decision: state === 'ACCEPT' ? 'ACCEPT' : 'DECLINE'
        },
      }
      const requestUrl = encodeURI(`/housemigrationrequests/${houseMigrationRequestId}`)
      api(requestUrl, requestOptions).then(({ data }) => {
        enqueueSnackbar('Changed successfully', {
          variant: 'success',
        })
        history.push('/mobile')
      }).catch(e => {
        if (e.response.status === 451) {
          // enqueueSnackbar(`Email not found. You need to sign up first. Use ${email || 'requested'} email`, {
          //   variant: 'error',
          // })
          localStorage.setItem('redirect', window.location)
          sessionStorage.setItem('email', decodeURIComponent(email || ''))
          sessionStorage.setItem('autoDecide', state === 'ACCEPT' ? 'ACCEPT' : 'DECLINE')

          // give time for user to read the error message
          return setTimeout(() => {window.location = '/sign-up'}, 2000)
        }

        enqueueSnackbar(e.response.data, {
          variant: 'error',
        })
        console.log(e)
      })
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      })
      console.log(e)
    }
    setPending(false)
  }

  return (
    <article>
      <h1 style={{ textAlign: 'center' }}>
        {`You are about to `}
        <h4 className={state === 'ACCEPT' ? classes.textGrant : classes.textDecline}>
          {state === 'ACCEPT' ? 'ACCEPT' : 'DECLINE'}
        </h4>{` ownership for a house ${houseName}`}
      </h1>
      <Box className={classes.buttonsWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          loading={pending}
          className={classes.button}
          onClick={() => handleSubmit()}
        >
          {state === 'ACCEPT' ? 'ACCEPT' : 'DECLINE'}
        </Button>
      </Box>
    </article>
  )
}

const HouseMigrationPageWithStyles = withStyles(styles)(HouseMigrationPage)
export { HouseMigrationPageWithStyles as HouseMigrationPage }
