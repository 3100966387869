import { combineReducers } from 'redux'

import { auth } from './auth.reducer'
import { users } from './users.reducer'
import { notifier } from './notifier.reducer'
import { device } from './device.reducer'
import { house } from './house.reducer'
import { system } from './system.reducer'

const rootReducer = combineReducers({
  auth,
  users,
  notifier,
  device,
  house,
  system,
})

export default rootReducer
