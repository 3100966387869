import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import App from './containers/App'
import theme from './theme'
import { store } from './helpers/store'
import { Footer } from './components/Footer'
import './index.css'
import * as serviceWorker from './serviceWorker'

const withFooter = WrappedComponent => () => [
  <WrappedComponent key="1"/>,
  <Footer key="2"/>
]

const Wrapper = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

const WrapperWithFooter = withFooter(Wrapper)

render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <WrapperWithFooter />
      </ThemeProvider>
    </SnackbarProvider>
  </MuiPickersUtilsProvider>
  , document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
