import { authHeader } from '../helpers'
import api from './api.service'
import { notificationService } from './notification.service'

export const userService = {
  login,
  forgotPassword,
  changePassword,
  logout,
  register,
  getMe,
  getAll,
  getById,
  update,
  delete: _delete,
  hasPushPermissions,
  magicRegisterSetPassword,
}

function login (email, password) {
  const requestOptions = {
    method: 'PUT',
    data: { email, password },
  }

  return api('login', requestOptions)
    .then(({ data }) => {
      const user = data.data.user
      if (user.role !== 'HOMEOWNER') {
        throw Error('You should be home owner to log in')
      }
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

function getMe () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return api('users/me', requestOptions)
}

function logout () {
  const requestOptions = {
    method: 'DELETE',
  }

  return api('logout', requestOptions)
    .then(() => {
      notificationService.destroy()
      localStorage.removeItem('user')
    })
}

function changePassword (forgotKey, newPassword) {
  const requestOptions = {
    method: 'POST',
    data: { forgotKey, newPassword }
  }

  return api('forgotpassword', requestOptions)
}

function magicRegisterSetPassword (magicKey, password) {
  const requestOptions = {
    method: 'PUT',
    data: { magicKey, password }
  }

  return api('magicRegisterSetPassword', requestOptions)
}

function forgotPassword (email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: { email },
  }

  return api('forgotpasswordrequest', requestOptions)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
  }

  return api('users', requestOptions)
}

function getById (id) {
  const requestOptions = {
    method: 'GET',
  }

  return api(`users/${id}`, requestOptions)
}

function register (user) {
  const requestOptions = {
    method: 'POST',
    data: user
  }

  return api('register', requestOptions)
}

function update (user) {
  const requestOptions = {
    method: 'PUT',
    data: user
  }

  return api(`users/${user.id}/`, requestOptions)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete (id) {
  const requestOptions = {
    method: 'DELETE',
  }

  return api(`users/${id}/`, requestOptions)
}

function hasPushPermissions () {
  let pushAllowed = true
  const loggedInUser = getLoggedInUser()
  if (loggedInUser && loggedInUser.hasOwnProperty('userOptions')) {
    loggedInUser.userOptions.forEach((data) => {
      if (data.optionName === 'pushAllowed') {
        pushAllowed = (data.optionValue === 'true')
      }
    })
  }
  return pushAllowed
}

function getLoggedInUser () {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch (e) {
    return false
  }

}
